import React, { useState } from 'react'
import "./navbar.scss"
import {Link} from "react-scroll"

const Navbar = () => {
    const [dispalay,setDisplay]=useState("false")
    const [yaxis,setyaxis]=useState(0)

    window.onscroll = () => {
        
        if(window.scrollY-yaxis>180){ 
              setDisplay("false")
        } 
        setyaxis(0)    
      };
      
    const changetoggle=()=>{
        setyaxis(window.scrollY)
   
        if(dispalay==="false")
        setDisplay("true")
        else{
            setDisplay("false")
        }
    }

    const linkclick=()=>{
        setDisplay("false")
    }

  return (
    <div className='nav'>
        <div className='navLogo'>
        <Link to="info" spy={true} smooth={true} offset={-100} duration={500} className='navMenuListItems' onClick={linkclick}>
        <img src='../images/logobg.png'/>
         </Link>
            
            
        </div>
        <div className='togglebutton' onClick={changetoggle}>
            <span></span>
            <span></span>
            <span></span>
        </div>
       
        <div className={`navMenus ${dispalay}`}>

        <Link to="about" spy={true} smooth={true} offset={-100} duration={500} className='navMenuListItems' onClick={linkclick}> <span >About Me </span></Link>
        <Link to="skill" spy={true} smooth={true} offset={-80} duration={500} className='navMenuListItems' onClick={linkclick}><span >Skills </span></Link>
        <Link to="project" spy={true} smooth={true} offset={-80} duration={500} className='navMenuListItems' onClick={linkclick}> <span >Projects </span></Link>
            
        </div>
        
        <div className={`navContactMe ${dispalay}`}>
        <Link to="contact" spy={true} smooth={true} offset={-80} duration={500}>
        <button>Contact Me</button>
        </Link>
        </div>
    </div>
  )
}

export default Navbar