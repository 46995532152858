import React from 'react'
import "./oneproject.scss"

const OneProject = ({project}) => {
  return (
    <div className='oneproject'>
        <img src={project.img}/>
        <div className='projectname'>{project.name}</div>
        <div className='projecttype'>{project.type}</div>
        <div className='projectdescription'>{project.description}</div>
        
        <div className='technology'>
            <div className='heading'> Technollogy:</div>
            
            {project.technologies.map((technology,index)=>(
            <span key={index}>{technology}</span>
          ))}
           
        </div>
        <div className='buttons'>
        <a href={project.livelink} target="_blank"> <button className='buttonDemo'>Live Demo</button></a>
        
        <a href={project.sourceCodelink} target="_blank"><button className='buttonSourceCode'>Source Code</button></a>
            
        </div>
    </div>
  )
}

export default OneProject