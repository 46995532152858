import React from 'react'
import "./contactme.scss"
import { GrMail } from 'react-icons/gr';
import { AiFillLinkedin } from 'react-icons/ai';
import { AiFillGithub } from 'react-icons/ai';


const Contactme = () => {
  return (
    <div className='contactme' id='contact'>
        <div className='contactmeHEading'>Contact Me</div>
        <div className='contactmeItems'>
        <a href='mailto:chiragdhawan16@gmail.com' target="_blank" className='contactmeCard'>
            <div>
                <div className='contactCardTop'>
                    <div className='contactCardImage'>
                        <GrMail className='icon'/>
                    </div>
                    <div className='contactCardHeading'>Email</div>
                </div>
                <div className='contactCardBottom'>Lets's get in touch.</div>
            </div>
            </a>
            <a href='https://www.linkedin.com/in/chiragdhawan16' target="_blank" className='contactmeCard'>
                <div className='contactCardTop'>
                    <div className='contactCardImage'>
                       <AiFillLinkedin className='icon'/> 
                    </div>
                    <div className='contactCardHeading'>Linkedin</div>
                </div>
                <div className='contactCardBottom'>Lets's Connect.</div>
            
            </a>

            <a href='https://github.com/chiragdhawan16' target="_blank" className='contactmeCard'>
            
                <div className='contactCardTop'>
                    <div className='contactCardImage'>
                       <AiFillGithub className='icon'/>
                    </div>
                    <div className='contactCardHeading'>Github</div>
                </div>
                <div className='contactCardBottom'>Check out my repos.</div>
           
            </a>
        </div>
    </div>
  )
}

export default Contactme