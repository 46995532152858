import React from 'react'
import "./skills.scss"
import SkillCategory from '../skillcategory/SkillCategory'



const Skills = () => {
  const frontend={type:"Frontend",
                  skills:["Html","CSS","JavaScript","React","Sass","Bootstrap","MaterialUI"]}
  
 const backend={type:"Backend",
            skills:["NodeJS","Express","MongoDB","RestAPI","Dot Net core","C#","SQL"]}

 const others={type:"Tools",
            skills:["Git","GitHub","VsCode","Postman","Render","Netlify","Visual Studio"]}
  

  return (
    <div className='skills' id='skill'>
      <div className='skillHeading'>My Skills</div>
      <div className='skillCtegories'>
        <SkillCategory data={frontend}/>
        <SkillCategory data={backend}/>
        <SkillCategory data={others}/>
        
      </div>
    </div>
  )
}

export default Skills