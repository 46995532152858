import React from 'react'
import "./info.scss"
import { Link } from 'react-scroll'

const Info = () => {
  return (
    <div className='info' id='info'>
        <div className='left'>
            <p className='leftHeading'>Hi there, I'm <span>Chirag</span></p>
            <p className='leftContent'>I'm a Full Stack Web Developer based in Ontario, Toronto.</p>
            <Link to="about" spy={true} smooth={true} offset={-100} duration={500}> <button>About</button></Link>
        </div>
        <div className='right'>
            <div className='rightImage'>
            <img src='../images/profile.png'/>
            </div>  
        </div>
    </div>
  )
}

export default Info