import React from 'react'
import "./about.scss"


const About = () => {
  return (
    <div className='about' id='about'>
              
        <div className='aboutHeading'>About Me</div>
        <div className='aboutContent'>
            <div className='aboutContentLeft'>
                <img src='../images/about.jpg'/>
            </div>
            <div className='aboutContentRight'>
                <p>Hi, I’m Chirag Dhawan,I did graduation from computer science.<br/>I’m a software engineer who loves creating websites and apps and I’ve been a web developer for years. I am passionate about creating beautiful websites that are easy to use and understand.<br/>After graduating from university, I immediately started working on my projects and learning as much as possible. My goal is to create intuitive, user-friendly, and functional websites.</p>
                
            </div>
        </div>

    </div>
  )
}

export default About