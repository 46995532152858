import React from 'react'
import "./skillcategory.scss"
import { AiFillHtml5 } from 'react-icons/ai';
import { BiCodeAlt } from 'react-icons/bi';
import { FaTools } from 'react-icons/fa';

const SkillCategory = ({data}) => {
  let displayfrontend="none"
  if(data.type==="Frontend") 
  {
    displayfrontend=""
  }
  let displaybackend="none"
  if(data.type==="Backend") 
  {
    displaybackend=""
  }
  let displaytools="none"
  if(data.type==="Tools") 
  {
    displaytools=""
  }
  
  
  return (
    <div className='skillCategory'>
        <div className='skillcategoryImage'>
        <AiFillHtml5 className='icon' style={{display:displayfrontend}}/>
        <BiCodeAlt className='icon' style={{display:displaybackend}}/>
        <FaTools className='icon' style={{display:displaytools}}/>
        
        </div>
        <div className='skilltype'>{data.type}</div>
        
        <hr/>
        

        <div className='skills'> 
          {data.skills.map((skill,index)=>(
            <div key={index}>{skill}</div>
          ))}
           
        </div>
    </div>
  )
}

export default SkillCategory