
import './App.css';
import About from './components/about/About';
import Contactme from './components/contactme/Contactme';
import Footer from './components/footer/Footer';
import Info from './components/info/Info';
import Navbar from './components/navbar/Navbar';
import Projects from './components/projects/Projects';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import Skills from './components/skills/Skills';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Info/>
      <About/>
      <Skills/>
      <Projects/>
      <Contactme/>
      <Footer/>
      
      
      
    </div>
  );
}

export default App;
