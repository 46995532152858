import React from 'react'
import "./projects.scss"
import OneProject from '../oneproject/OneProject'


const Projects = () => {
  const project1={img:"../images/netflix_frontend.png",
                name:"Netflix Clone",
                type:"Video Streaming",
                description:"Netflix-Clone is copy of a streaming app Netflix which i created for educational puspose and this app provide a wide variety of shows, movies and more on thousands of internet-connected devices.You can watch as much as you want, whenever you want. There's always something new to discover and new TV shows and movies are added every week!",
                technologies:["Html","CSS","Node.js","Express","Sass","MaterialUI","Javascript","MongoDB","JWT"],
                livelink:"https://netflix-clone-chirag.netlify.app/",
                sourceCodelink:"https://github.com/chiragdhawan16/Netflix_Frontend"
              }

const project2={img:"../images/netflix_admin.png",
                name:"Netflix Clone Admin",
                type:"Admin Pannel",
                description:"Netflix Clone Admin is the User Interface (UI) of the backend of Netflix Clone application. It provides lots of tools to manage the entire web application. With the help of the admin dashboard, you can analyze the performance of a web application. The Admin dashboard provides real-time information to the user,movies and lists and you can manage all of this information.",
                technologies:["Html","CSS","Node.js","Express","Sass","MaterialUI","Javascript","MongoDB","JWT"],
                livelink:"https://netflix-clone-admin-chirag.netlify.app/",
                sourceCodelink:"https://github.com/chiragdhawan16/netflix_admin"
              }

  return (
    <div className='projects' id='project'>
        <div className='projectsHeading'>Projects</div>
        <div className='onePtojectContainer'>
            <OneProject project={project1}/>
            <OneProject project={project2}/>
            {/* <OneProject project={project1}/>
            <OneProject project={project1}/> */}
           
        </div>
    </div>
  )
}

export default Projects