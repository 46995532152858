import React from 'react'
import "./footer.scss"

const Footer = () => {
    const year=new Date().getFullYear();
  return (
    <div className='footer'>@ Copyright {year} ChiragDhawan. All Right Reserved</div>
  )
}

export default Footer